import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-elements-placeholder',
  templateUrl: './no-elements-placeholder.component.html',
  styleUrls: ['./no-elements-placeholder.component.scss'],
  standalone: true,
  imports: [],
})
export class NoElementsPlaceholderComponent {
  @Input() icon = 'pi-no-sim';
  @Input() text = '';
}
