const SICK_VERSION_REGEX =
  '^V?((?<prefix>[ABS])' /* Version may start with up to two letters (V,A,B,S). If two letters used, V must be the first one! */ +
  '(?!.*\\k<prefix>))?' /* Each letter must only occour just one time per version! */ +
  '\\d+(\\.\\d+){1,3}' /* Minimum 2 & maximum 4 groups of positive numbers */ +
  '((?<suffix>[ABS])(?!\\k<suffix>)){0,2}$'; /* Up to 2 letters as suffix allowed (A,B,S) */
const SEMVER_REGEX =
  '^(0|[1-9]\\d*)' +
  '\\.(0|[1-9]\\d*)' +
  '\\.(0|[1-9]\\d*)' +
  '(?:-(' +
  '(?:0|[1-9]\\d*|\\d*[a-zA-Z-][0-9a-zA-Z-]*)' +
  '(?:\\.(?:0|[1-9]\\d*|\\d*[a-zA-Z-][0-9a-zA-Z-]*))' +
  '*))' +
  '?(?:\\+([0-9a-zA-Z-]+(?:\\.[0-9a-zA-Z-]+)*))?$';

export function isVersionValid(artifactVersion: string): boolean {
  return isSemverVersion(artifactVersion) || isSickVersion(artifactVersion);
}

export function isSickVersion(artifactVersion: string): boolean {
  return new RegExp(SICK_VERSION_REGEX).test(artifactVersion);
}

export function isSemverVersion(artifactVersion: string): boolean {
  return new RegExp(SEMVER_REGEX).test(artifactVersion);
}
