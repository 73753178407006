import { Component, ElementRef, Inject, inject, Input, OnInit, ViewChild } from '@angular/core';
import { InternationalizationService, LangCodes } from '@art-repo/shared/services';
import { FEATURE_TOGGLE } from '@art-repo/shared/utils';
import { ArtiEnvironment, ENVIRONMENT } from '@environments/model';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'app-sick-header',
  templateUrl: './sick-header.component.html',
  styleUrls: ['./sick-header.component.scss'],
  standalone: true,
  imports: [MenuComponent, MenuItemComponent, TranslateModule],
})
export class SickHeaderComponent implements OnInit {
  public FEATURE_TOGGLE_APPS_MENU_IN_HEADER = inject(FEATURE_TOGGLE)('APPS_MENU_IN_HEADER');

  public readonly languages = this.internationalizationService.languages;

  @Input() public applicationTitle = '';

  @ViewChild('appMenu')
  appMenu: ElementRef | undefined;

  @ViewChild('userMenu')
  userMenu: ElementRef | undefined;

  language = 'en';

  public readonly apps = [
    {
      label: 'Webshop',
      url: 'https://www.sick.com',
      icon: 'assets/images/apps/sick-logo-small.svg',
    },
    {
      label: 'Digital Service Catalog',
      url: 'https://cloud.sick.com',
      icon: '',
    },
    {
      label: 'Support Portal',
      url: 'https://support.sick.com',
      icon: 'assets/images/apps/SupportPortal.svg',
    },
    {
      label: 'AppPool',
      url: 'https://apppool.cloud.sick.com',
      icon: 'assets/images/apps/AppPool.png',
    },
    {
      label: 'AssetHub',
      url: 'https://assethub.cloud.sick.com',
      icon: 'assets/images/apps/AssetHub.png',
    },
    {
      label: 'dStudio',
      url: 'https://dstudio.cloud.sick.com',
      icon: 'assets/images/apps/dStudio.png',
    },
    {
      label: 'Dynamic Data Display',
      url: 'https://sd3.cloud.sick.com',
      icon: 'assets/images/apps/DynamicDataDisplay.jpg',
    },
    {
      label: 'Field Analytics',
      url: 'https://fa.analytics.cloud.sick.com',
      icon: 'assets/images/apps/FieldAnalytics.jpg',
    },
    {
      label: 'Package Analytics',
      url: 'https://package.analytics.cloud.sick.com',
      icon: 'assets/images/apps/PackageAnalytics.png',
    },
  ];

  constructor(
    private auth: OAuthService,
    private internationalizationService: InternationalizationService,
    private translateService: TranslateService,
    @Inject(ENVIRONMENT) private environment: ArtiEnvironment,
  ) {}

  ngOnInit() {
    this.language = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.language = event.lang;
    });
  }

  onShowProfile() {
    window.open(this.environment.oidcProfile, '_blank');
  }

  onChangePassword() {
    window.open(this.environment.oidcPassChange, '_blank');
  }

  onLogout() {
    this.auth.logOut();
  }

  onLanguageChange(code: LangCodes) {
    this.internationalizationService.setLanguage(code);
  }

  onOpenInNewWindow(url: string) {
    window.open(url, '_blank');
  }
}
