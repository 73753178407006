import { Routes } from '@angular/router';
import { ArtifactDetailsComponent, ArtifactOverviewComponent } from './artifacts/components';
import { DeviceOverviewComponent } from './devices/components';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'artifacts',
  },
  {
    path: 'artifacts',
    component: ArtifactOverviewComponent,
    pathMatch: 'full',
  },
  {
    path: 'artifacts',
    children: [
      {
        path: ':artifactId',
        component: ArtifactDetailsComponent,
        pathMatch: 'full',
      },
      {
        path: ':artifactId',
        children: [
          {
            path: 'download',
            component: ArtifactDetailsComponent,
            data: {
              fetchArtifact: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: 'devices',
    component: DeviceOverviewComponent,
  },
];
