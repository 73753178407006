import { InjectionToken } from '@angular/core';
import { ArtiEnvironment } from '@environments/model';

export type FeatureToggleEvalFn = (name: string) => boolean;

export const featureToggleEvalFactory =
  (env: ArtiEnvironment): FeatureToggleEvalFn =>
  (toggle: string) =>
    featureToggleActive(toggle, env);

export const FEATURE_TOGGLE = new InjectionToken<(name: string) => boolean>('FeatureToggleEvaluator');

function featureToggleActive(name: string, env: ArtiEnvironment): boolean {
  return Boolean(env.featureToggles && env.featureToggles[name] === true);
}
