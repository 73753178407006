import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ArtiEnvironment, ENVIRONMENT } from '@environments/model';
import { OAuthResourceServerErrorHandler, OAuthStorage } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OAuthInterceptor implements HttpInterceptor {
  constructor(
    private oauthStorage: OAuthStorage,
    private oauthErrorHandler: OAuthResourceServerErrorHandler,
    @Inject(ENVIRONMENT) private environment: ArtiEnvironment,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (
      request.url.startsWith(this.environment.apiUrl) ||
      request.url.startsWith(this.environment.groupManagement.apiUrl)
    ) {
      const token = this.oauthStorage.getItem('access_token');

      if (token) {
        return next
          .handle(
            request.clone({
              headers: request.headers.set('Authorization', `Bearer ${token}`),
              withCredentials: false,
            }),
          )
          .pipe(catchError((err) => this.oauthErrorHandler.handleError(err)));
      }
    }

    return next.handle(request);
  }
}
