import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-item-dialog',
  templateUrl: './item-dialog.component.html',
  styleUrls: ['./item-dialog.component.scss'],
  standalone: true,
  imports: [],
})
export class ItemDialogComponent implements OnInit {
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) {}
  public items: string[] = [];
  ngOnInit() {
    this.items = this.config.data?.items;
  }

  close() {
    this.ref.close();
  }
}
