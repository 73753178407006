import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Pipe({
  name: 'localizedDate',
  pure: false,
  standalone: true,
})
export class LocalizedDatePipe implements PipeTransform {
  private onLangChange?: Subscription;
  private lastArgs?: { value?: Date | string | number; format?: string };
  private transformation: string | null = null;

  constructor(
    private translateService: TranslateService,
    private datePipe: DatePipe,
  ) {}

  transform(value?: Date | string | number, format?: string): string | null {
    this.resetLastArgsOnLangChange();
    const useLastTransformation = value === this.lastArgs?.value && format === this.lastArgs?.format;
    if (!useLastTransformation) {
      {
        this.lastArgs = { value, format };
        this.transformation =
          value !== undefined
            ? this.datePipe.transform(
                value,
                this.handleCustomFormat(format),
                undefined,
                this.translateService.currentLang,
              )
            : '-';
      }
    }
    return this.transformation;
  }

  private resetLastArgsOnLangChange() {
    if (!this.onLangChange) {
      this.onLangChange = this.translateService.onLangChange.subscribe(() => {
        this.lastArgs = undefined;
      });
    }
  }

  private handleCustomFormat(format: string | undefined): string | undefined {
    if (format === 'shortDateZF') {
      // short date, zero-filled, (e.g. 08/03/22 or 03.08.22)
      return this.translateService.instant('general.shortDateZF');
    }
    if (format === 'mediumDateZF') {
      // medium date, zero-filled, (e.g. Aug 02, 2022 or 03.08.2022)
      return this.translateService.instant('general.mediumDateZF');
    }
    return format;
  }
}
