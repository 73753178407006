import { SlicePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { ItemDialogComponent } from '@art-repo/shared/components';
import { Artifact } from '@art-repo/shared/models';
import { HumanReadableBytesPipe, LocalizedDatePipe } from '@art-repo/shared/pipes';
import { ArtifactService } from '@art-repo/shared/services';
import { Logger } from '@art-repo/shared/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonDirective } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { filter, map, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-artifact-details',
  standalone: true,
  imports: [HumanReadableBytesPipe, LocalizedDatePipe, ButtonDirective, TranslateModule, SlicePipe],
  templateUrl: './artifact-details.component.html',
  styleUrl: './artifact-details.component.scss',
})
export class ArtifactDetailsComponent implements OnInit {
  private logger = new Logger('ArtifactDetailsComponent');

  artifact: Artifact | undefined;
  loading = true;

  constructor(
    private artifactService: ArtifactService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        untilDestroyed(this),
        filter((params) => params['artifactId'] !== undefined),
        map((params) => params['artifactId']),
        switchMap((artifactId: string) => {
          return this.artifactService.getArtifact(artifactId);
        }),
        switchMap((artifact: Artifact) => {
          this.artifact = artifact;
          return this.route.data;
        }),
      )
      .subscribe({
        next: (routeData: Data) => {
          if (routeData['fetchArtifact']) {
            this.onDownload();
          }
          this.loading = false;
        },
        error: (err) => {
          this.logger.error(err);
          this.loading = false;
        },
      });
  }

  onDownload() {
    if (this.artifact !== undefined) {
      this.artifactService.downloadArtifact({
        artifactId: this.artifact.artifactId,
        fileName: this.artifact.fileName,
      });
    }
  }

  backToList() {
    this.router.navigateByUrl('/artifacts');
  }

  allNumbers(productFamily: string, version: string, itemNumbers: string[]) {
    this.dialogService.open(ItemDialogComponent, {
      data: {
        items: itemNumbers,
      },
      header: productFamily + ', Version: ' + version,
      style: {
        'max-width': '80%',
        'min-width': '30%',
        'min-height': '50%',
        'max-height': '80%',
      },
    });
  }
}
