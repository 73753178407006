<app-accept-dismiss-modal
  key="delete-artifact-modal"
  header="deleteArtifactDialog.header"
  message="deleteArtifactDialog.message"
  acceptButton="buttons.ok"
  cancelButton="buttons.cancel"
>
</app-accept-dismiss-modal>

<p-scrollPanel [style]="{ width: '100%', height: '100%' }">
  <div class="table">
    <p-table
      [value]="artifacts"
      sortField="artifactVersion"
      [sortOrder]="-1"
      [defaultSortOrder]="-1"
      [customSort]="true"
      (sortFunction)="sortArtifacts($event)"
      [loading]="searching"
    >
      <ng-template pTemplate="sorticon" let-sortOrder>
        @if (sortOrder === -1) {
          <i class="pi icon pi-sort-amount-down"></i>
        }
        @if (sortOrder === 1) {
          <i class="pi icon pi-sort-amount-up-alt"></i>
        }
        @if (sortOrder === 0) {
          <i class="pi icon pi-sort-alt"></i>
        }
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 25%">{{ 'artifactTable.productFamily' | translate }}</th>
          <th style="width: 50%">{{ 'artifactTable.itemNumbers' | translate }}</th>
          <th style="width: 10%" pSortableColumn="artifactVersion">
            {{ 'artifactTable.version' | translate }} <p-sortIcon field="artifactVersion"></p-sortIcon>
          </th>
          <th style="width: 15%">{{ 'artifactTable.updated' | translate }}</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-artifact>
        <tr>
          <td>{{ artifact.productFamily }}</td>
          <td>
            @if (artifact.itemNumbers[0] !== '') {
              <div>
                @for (num of artifact.itemNumbers | slice: 0 : 3; track num) {
                  <div>
                    <a> {{ '• ' + num }}</a>
                  </div>
                }
                @if (artifact.itemNumbers.length === 4) {
                  <div>
                    <a>{{ '• ' + artifact.itemNumbers[3] }}</a>
                  </div>
                }
                @if (artifact.itemNumbers.length > 4) {
                  <div>
                    <button
                      class="moreBtn"
                      (click)="allNumbers(artifact.productFamily, artifact.artifactVersion, artifact.itemNumbers)"
                    >
                      {{ 'artifactTable.more' | translate }}
                    </button>
                  </div>
                }
              </div>
            }
          </td>
          <td>{{ artifact.artifactVersion }}</td>
          @if (artifact.updatedAt === null) {
            <td>
              {{ 'artifactTable.on' | translate }}
              {{ artifact.createdAt | localizedDate: 'mediumDateZF' }}
              {{ 'artifactTable.by' | translate }}
              {{ artifact.createdBy }}
            </td>
          }
          @if (artifact.updatedAt !== null) {
            <td>
              {{ 'artifactTable.on' | translate }}
              {{ artifact.updatedAt | localizedDate: 'mediumDateZF' }}
              {{ 'artifactTable.by' | translate }}
              {{ artifact.updatedBy }}
            </td>
          }
          <td>
            <div class="buttons">
              <button
                pButton
                icon="pi pi-download"
                class="ui-button-bare-neutral tableButton"
                (click)="artifactService.downloadArtifact(artifact)"
              ></button>
              @if (artifact.isEditable) {
                <button
                  pButton
                  icon="pi pi-delete"
                  class="ui-button-bare-neutral tableButton"
                  (click)="onDeleteClick(artifact.artifactId)"
                ></button>
              }
              <button
                pButton
                icon="pi pi-content-copy"
                class="ui-button-bare-neutral tableButton"
                (click)="copyDeepLink(artifact.artifactId)"
              ></button>
              <button
                pButton
                icon="pi pi-chevron-right"
                class="ui-button-bare-neutral tableButton"
                (click)="navigateToDetails(artifact.artifactId)"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">
            <app-no-elements-placeholder
              icon="pi-zoom-out"
              text="{{ 'noEntry.artifactText' | translate }}"
            ></app-no-elements-placeholder>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-scrollPanel>
