import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Device, Group } from '@art-repo/shared/models';
import { DeviceService, GroupManagementService } from '@art-repo/shared/services';
import { Logger } from '@art-repo/shared/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MessageService, PrimeTemplate } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MultiSelect, MultiSelectFilterEvent, MultiSelectModule } from 'primeng/multiselect';
import { Subject, debounceTime, distinctUntilChanged, firstValueFrom, switchMap } from 'rxjs';
import { ArtiEnvironment, ENVIRONMENT } from './../../../../environments/model';
import { NgClass } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonDirective } from 'primeng/button';
import { FormsModule } from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'app-add-device-dialog',
  templateUrl: './add-device-dialog.component.html',
  styleUrls: ['./add-device-dialog.component.scss'],
  standalone: true,
  imports: [MultiSelectModule, FormsModule, PrimeTemplate, ButtonDirective, InputTextModule, NgClass, TranslateModule],
})
export class AddDeviceDialogComponent implements OnInit {
  @ViewChild('editGroupSelect')
  private editGroupSelect?: MultiSelect;

  @ViewChild('downloadGroupSelect')
  private downloadGroupSelect?: MultiSelect;

  private logger: Logger = new Logger('AddDeviceDialogComponent');

  public partNumbers: { value: string }[] = [{ value: '' }];

  constructor(
    private ref: DynamicDialogRef,
    private deviceService: DeviceService,
    private messageService: MessageService,
    private groupService: GroupManagementService,
    private translateService: TranslateService,
    public config: DynamicDialogConfig,
    @Inject(ENVIRONMENT) private environment: ArtiEnvironment,
  ) {}

  public editGroupSearchChange = new Subject<string>();
  public downloadGroupSearchChange = new Subject<string>();
  public itemNumber = '';
  public productFamily = '';
  public itemNumberValid = true;
  public editGroups: Group[] = [];
  public downloadGroups: Group[] = [];
  public selectedEditGroups: string[] = [];
  public selectedDownloadGroups: string[] = [];

  ngOnInit(): void {
    this.editGroupSearchChange
      .pipe(untilDestroyed(this))
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => this.searchEditGroups(value));
    this.downloadGroupSearchChange
      .pipe(untilDestroyed(this))
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => this.searchDownloadGroups(value));
    if (this.config.data?.isEditMode) {
      this.loadData();
    }
  }

  public onCancelClick(): void {
    this.ref.close();
  }

  public onFilterEditGroup(event: MultiSelectFilterEvent) {
    this.editGroupSearchChange.next(event.filter);
  }

  public onFilterDownloadGroup(event: MultiSelectFilterEvent) {
    this.downloadGroupSearchChange.next(event.filter);
  }

  public searchEditGroups(searchString: string) {
    if (searchString.trim().length > 0) {
      this.groupService
        .getGroups(searchString, 20, 0)
        .pipe(
          switchMap((groups) => {
            this.editGroups = groups.page;
            if (typeof this.editGroupSelect !== 'undefined') {
              this.editGroupSelect._filteredOptions = groups.page;
            }
            // get already selected groups and add to multiselect
            return this.groupService.getGroupsByIds(this.selectedEditGroups);
          }),
        )
        .subscribe({
          next: (groups) => {
            const groupsToConcat = groups.groups.filter(
              (item) => this.editGroups.map((x) => x.id).indexOf(item.id) < 0,
            );
            this.editGroups = this.editGroups.concat(groupsToConcat);
            if (typeof this.editGroupSelect !== 'undefined') {
              this.editGroupSelect._filteredOptions = this.editGroupSelect._filteredOptions
                ? this.editGroupSelect._filteredOptions.concat(groupsToConcat)
                : groupsToConcat;
            }
          },
        });
    }
  }

  public searchDownloadGroups(searchString: string) {
    if (searchString.trim().length > 0) {
      this.groupService
        .getGroups(searchString, 20, 0)
        .pipe(
          switchMap((groups) => {
            this.downloadGroups = groups.page;
            if (typeof this.downloadGroupSelect !== 'undefined') {
              this.downloadGroupSelect._filteredOptions = groups.page;
            }
            // get already selected groups and add to multiselect
            return this.groupService.getGroupsByIds(this.selectedDownloadGroups);
          }),
        )
        .subscribe({
          next: (groups) => {
            const groupsToConcat = groups.groups.filter(
              (item) => this.downloadGroups.map((x) => x.id).indexOf(item.id) < 0,
            );
            this.downloadGroups = this.downloadGroups.concat(groupsToConcat);
            if (typeof this.downloadGroupSelect !== 'undefined') {
              this.downloadGroupSelect._filteredOptions = this.downloadGroupSelect._filteredOptions
                ? this.downloadGroupSelect._filteredOptions.concat(groupsToConcat)
                : groupsToConcat;
            }
          },
        });
    }
  }

  public async onAddClick(): Promise<void> {
    const selectedNumbers = this.partNumbers
      .map((number) => {
        return number.value;
      })
      .filter((value) => {
        return value !== '';
      });
    try {
      await firstValueFrom(
        this.deviceService.createDevice(
          selectedNumbers,
          this.productFamily,
          this.selectedDownloadGroups,
          this.selectedEditGroups,
        ),
      );
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('addDeviceDialog.successAddSummary'),
        detail: this.translateService.instant('addDeviceDialog.successAddDetail'),
      });
      this.ref.close();
    } catch (e) {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('addDeviceDialog.errorAddSummary'),
        detail: this.translateService.instant('addDeviceDialog.errorAddDetail'),
      });
      this.logger.error(e);
    }
  }

  public async onUpdateClick(): Promise<void> {
    const device: Device = this.config.data.device;
    const selectedNumbers = this.partNumbers
      .map((number) => {
        return number.value;
      })
      .filter((value) => {
        return value !== '';
      });
    try {
      await firstValueFrom(
        this.deviceService.updateDevice(
          device.deviceId,
          selectedNumbers,
          this.productFamily,
          this.selectedDownloadGroups,
          this.selectedEditGroups,
        ),
      );
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('addDeviceDialog.successEditSummary'),
        detail: this.translateService.instant('addDeviceDialog.successEditDetail'),
      });
      this.ref.close();
    } catch (e) {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('addDeviceDialog.errorEditSummary'),
        detail: this.translateService.instant('addDeviceDialog.errorEditDetail'),
      });
      this.logger.error(e);
    }
  }

  public addNumber() {
    this.partNumbers.push({ value: '' });
  }

  public removeNumber(index: number) {
    this.partNumbers.splice(index, 1);
    this.validateItemNumbers();
  }

  public validateItemNumbers(number?: string, index?: number) {
    //check for duplicates in numbers
    let values = this.partNumbers.map((item) => {
      return item.value;
    });

    if (number !== undefined && index !== undefined) {
      values[index] = number;
    }

    values = values.filter((value) => {
      return value !== '';
    });

    this.itemNumberValid = !values.some((item, i) => {
      return values.indexOf(item) !== i;
    });
  }

  public openGroupUI(): void {
    window.open(`${this.environment.groupManagement.baseUrl}/my-groups`, '_blank');
  }

  private loadData() {
    const device: Device = this.config.data.device;
    this.selectedEditGroups = device.editGroups;
    this.selectedDownloadGroups = device.downloadGroups;
    this.productFamily = device.productFamily;
    this.partNumbers = device.itemNumbers.map((x) => ({
      value: x,
    }));
    this.groupService.getGroupsByIds(device.editGroups).subscribe({
      next: (groups) => {
        this.editGroups = groups.groups;
        if (typeof this.editGroupSelect !== 'undefined') {
          this.editGroupSelect._filteredOptions = groups.groups;
        }
      },
    });
    this.groupService.getGroupsByIds(device.downloadGroups).subscribe({
      next: (groups) => {
        this.downloadGroups = groups.groups;
        if (typeof this.downloadGroupSelect !== 'undefined') {
          this.downloadGroupSelect._filteredOptions = groups.groups;
        }
      },
    });
  }
}
