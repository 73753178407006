<div class="overview">
  <app-search-bar
    #searchBar
    [buttonText]="'addButton.addArtifact' | translate"
    (addEntity)="addArtifact()"
    [showAddButton]="true"
    [searchValue]="searchValue"
    (searchValueChange)="searchArtifacts($event)"
  ></app-search-bar>
  <app-artifact-table #artifactTable class="table"></app-artifact-table>
</div>
