import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { routes } from '@art-repo/app-routing';
import { OAuthInterceptor } from '@art-repo/shared/interceptors';
import { AppInitializerService } from '@art-repo/shared/services';
import { environment } from '@environments/environment';
import { ENVIRONMENT } from '@environments/model';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { AppComponent } from './app/app.component';
import { ArtiEnvironment } from './environments/model';
import { DatePipe } from '@angular/common';
import { FEATURE_TOGGLE, featureToggleEvalFactory } from '@art-repo/shared/utils';

if (environment.production) {
  enableProdMode();
}

function bootstrapArtifactRepo(env: ArtiEnvironment) {
  bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(
        OAuthModule.forRoot(),
        BrowserModule,
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
            deps: [HttpClient],
          },
        }),
      ),
      DialogService,
      MessageService,
      ConfirmationService,
      DatePipe,
      {
        provide: APP_INITIALIZER,
        useFactory: (appInitializerService: AppInitializerService) => () => appInitializerService.initialize(),
        multi: true,
        deps: [AppInitializerService],
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: OAuthInterceptor,
        multi: true,
      },
      {
        provide: OAuthStorage,
        useFactory: () => localStorage,
      },
      {
        provide: ENVIRONMENT,
        useValue: env,
      },
      {
        provide: FEATURE_TOGGLE,
        useFactory: featureToggleEvalFactory,
        deps: [ENVIRONMENT],
      },
      provideAnimations(),
      provideHttpClient(withInterceptorsFromDi()),
      provideRouter(
        routes,
        withRouterConfig({
          onSameUrlNavigation: 'reload',
          paramsInheritanceStrategy: 'always',
        }),
      ),
    ],
  }).catch((err) => console.error(err));
}

if (environment.dynamicSettings === true) {
  fetch('./assets/app-settings/settings.json')
    .then((res) => res.json())
    .then((settings) => bootstrapArtifactRepo(Object.assign(environment, settings)))
    .catch((err) => {
      console.error('Unable to load dynamic application configuration', err);
      return err;
    });
} else {
  bootstrapArtifactRepo(environment);
}
