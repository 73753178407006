import { AsyncPipe, NgClass } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Artifact, Device } from '@art-repo/shared/models';
import { HumanReadableBytesPipe } from '@art-repo/shared/pipes';
import { ArtifactService, DeviceService, Upload, UploadService, UserService } from '@art-repo/shared/services';
import { Logger, isVersionValid } from '@art-repo/shared/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonDirective } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileSelectEvent, FileUpload, FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, Subject, firstValueFrom, map, of } from 'rxjs';

interface WithLabel {
  label: string;
}

@UntilDestroy()
@Component({
  selector: 'app-add-firmware-dialog',
  templateUrl: './add-firmware-dialog.component.html',
  styleUrls: ['./add-firmware-dialog.component.scss'],
  standalone: true,
  imports: [
    AutoCompleteModule,
    FormsModule,
    InputTextModule,
    NgClass,
    TooltipModule,
    ButtonDirective,
    FileUploadModule,
    ProgressBarModule,
    AsyncPipe,
    TranslateModule,
    HumanReadableBytesPipe,
  ],
})
export class AddFirmwareDialogComponent implements OnInit {
  @ViewChild('fileInput')
  private fileInput?: FileUpload;

  private logger: Logger = new Logger(this.constructor.name);
  private deviceFilterChange = new Subject<string>();
  public upload: Upload | undefined;
  public isUploading = false;

  public devices$: Observable<(Device & WithLabel)[]> = of([]);
  public selectedDevice: Device | undefined;

  constructor(
    private ref: DynamicDialogRef,
    private artifactService: ArtifactService,
    private deviceService: DeviceService,
    private messageService: MessageService,
    private uploads: UploadService,
    private userService: UserService,
  ) {}

  // Artifact version itself
  public version = '';
  // Flags to enable/disable submit button
  public artifactVersionValid = false;
  // Show detailed infos which versioning requirements must be fulfilled
  public displayVersionHelp = false;
  public file: File | null = null;
  public fileErrors: string[] = [];

  ngOnInit(): void {
    this.deviceFilterChange.pipe(untilDestroyed(this)).subscribe((value) => this.searchDevices(value));
  }

  public onCancelClick(): void {
    this.ref.close();
  }

  public onFilterDevices(event: AutoCompleteCompleteEvent): void {
    this.deviceFilterChange.next(event.query);
  }

  public searchDevices(searchString: string) {
    this.devices$ = this.deviceService.searchDevice(searchString).pipe(
      map<Device[], (Device & WithLabel)[]>((devices) => {
        return devices
          .filter((value) => {
            return this.userService.isMemberOfGroupId(value.editGroups);
          })
          .map((device) => {
            return { ...device, label: this.getLabel(device) };
          });
      }),
    );
  }

  private getLabel(device: Device): string {
    let label = '';
    switch (device.itemNumbers.length) {
      case 0:
        label = device.productFamily;
        break;
      case 1:
        label = `${device.productFamily} - ${device.itemNumbers[0]}`;
        break;
      default:
        label += `${device.productFamily} - ${device.itemNumbers[0]} ...`;
        break;
    }
    return label;
  }

  public async onAddClick(): Promise<void> {
    let artifact: Artifact;
    this.isUploading = true;
    let file: File = {} as File;
    if (this.file === null) {
      return;
    } else {
      file = this.file;
    }
    if (typeof this.selectedDevice === 'undefined') {
      return;
    }
    this.messageService.add({
      severity: 'info',
      summary: 'Upload',
      detail: `File upload for ${file.name} started.`,
    });
    try {
      artifact = await firstValueFrom(
        this.artifactService.createArtifact(this.selectedDevice.deviceId, this.version, {
          name: this.file.name,
          length: this.file.size,
        }),
      );
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        this.messageService.add({
          severity: 'error',
          summary: e.statusText || 'Error',
          detail: e.error,
        });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'DB-Error',
          detail: 'Error during creation of artifact in database!',
        });
      }
      this.isUploading = false;
      this.logger.error(e);
      return;
    }

    const formData = new FormData();
    Object.keys(artifact.fields).forEach((key) => {
      formData.append(key, artifact.fields[key]);
    });
    formData.append('file', file);

    try {
      this.uploads
        .upload(artifact.url, formData)
        .pipe(untilDestroyed(this))
        .subscribe((upload) => {
          this.upload = upload;
          if (upload.state === 'DONE') {
            this.isUploading = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Upload',
              detail: `File ${file.name} was successfully uploaded.`,
            });
            this.ref.close();
          }
        });
    } catch (e) {
      this.messageService.add({
        severity: 'error',
        summary: 'Upload Error',
        detail: `Error during uploading ${file.name} to the storage!`,
      });
      this.logger.error(e, file.name);
    }
  }

  public handleFile(event: FileSelectEvent) {
    this.resetFile();
    this.file = event.files[0];
  }

  public resetFile() {
    this.file = null;
    this.fileErrors = [];
  }

  public removeFile() {
    this.fileInput?.clear();
    this.resetFile();
  }

  onVersionLeaveFocus() {
    if (this.version) {
      this.displayVersionHelp = !this.artifactVersionValid;
    }
  }

  validateVersion(version: string) {
    this.artifactVersionValid = isVersionValid(version);
    if (this.artifactVersionValid || !version) {
      this.displayVersionHelp = false;
    }
  }
}
