import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FooterComponent } from './shared/components/footer/footer.component';
import { ToastModule } from 'primeng/toast';
import { RouterOutlet } from '@angular/router';
import { TabMenuModule } from 'primeng/tabmenu';
import { SickHeaderComponent } from './shared/components/sick-header/sick-header.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [SickHeaderComponent, TabMenuModule, RouterOutlet, ToastModule, FooterComponent],
})
@UntilDestroy()
export class AppComponent implements OnInit {
  constructor(private translateService: TranslateService) {}

  public items: MenuItem[] = [];

  ngOnInit() {
    this.setupNavItems();

    // Update nav when language changes
    this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => this.setupNavItems());
  }

  private setupNavItems(): void {
    this.items = [
      {
        routerLink: 'artifacts',
        label: this.translateService.instant('tabMenu.artifacts'),
      },
      {
        routerLink: 'devices',
        label: this.translateService.instant('tabMenu.devices'),
      },
    ];
  }
}
