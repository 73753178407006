import debug from 'debug';
import { environment } from '@environments/environment';

export class Logger {
  /* eslint-disable @typescript-eslint/no-unsafe-function-type */
  public debug: Function;
  public info: Function;
  public warn: Function;
  public error: Function;
  /* eslint-enable @typescript-eslint/no-unsafe-function-type */

  static {
    if (localStorage['debug'] === undefined) {
      localStorage['debug'] = environment.debug;
    }
  }

  constructor(namespace: string) {
    const scope = debug('ArtifactRepository').extend(namespace);

    // Create log level methods
    const levels = Logger.generateLevels(scope);
    this.debug = levels.debug;
    this.info = levels.info;
    this.warn = levels.warn;
    this.error = levels.error;
  }

  private static generateLevels(scope: debug.Debugger) {
    return {
      debug: scope.extend('debug'),
      info: scope.extend('info'),
      warn: scope.extend('warn'),
      error: scope.extend('error'),
    };
  }
}
