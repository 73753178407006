import { Injectable } from '@angular/core';
import { GroupManagementService } from './group-management.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private groupService: GroupManagementService) {}

  private userGroups: string[] = [];

  public getUserGroups(): string[] {
    return this.userGroups;
  }

  public async loadUserGroups() {
    this.userGroups = (await firstValueFrom(this.groupService.getUserGroups())).page.map((x) => x.id);
  }

  /* Checks if the currently authenticated user is member of a given group or array of groups*/
  public isMemberOfGroupId(groupIds: string | string[]): boolean {
    let isMember = false;
    if (Array.isArray(groupIds)) {
      for (const id of groupIds) {
        if (this.userGroups.includes(id)) {
          isMember = true;
          break;
        }
      }
    } else {
      isMember = this.userGroups.includes(groupIds);
    }
    return isMember;
  }
}
