<p-confirmDialog
  #confirmation
  [header]="header | translate"
  [message]="message | translate"
  [key]="key"
  appendTo="body"
  [dismissableMask]="true"
  [closable]="false"
>
  <p-footer>
    <button
      pButton
      type="button"
      class="ui-button-secondary"
      (click)="confirmation.reject()"
      [label]="cancelButton | translate"
    ></button>
    <button pButton type="button" (click)="confirmation.accept()" [label]="acceptButton | translate"></button>
  </p-footer>
</p-confirmDialog>
