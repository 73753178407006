import { Pipe, PipeTransform } from '@angular/core';

interface FormatInfo {
  limit: number;
  unit: string;
}

@Pipe({
  name: 'humanReadableBytes',
  standalone: true,
})
export class HumanReadableBytesPipe implements PipeTransform {
  private metric: FormatInfo[] = [
    { limit: 1, unit: 'B' },
    { limit: 1000, unit: 'kB' },
    { limit: 1000 * 1000, unit: 'MB' },
    { limit: 1000 * 1000 * 1000, unit: 'GB' },
    { limit: 1000 * 1000 * 1000 * 1000, unit: 'TB' },
  ];
  private iec: FormatInfo[] = [
    { limit: 1, unit: 'B' },
    { limit: 1024, unit: 'KiB' },
    { limit: 1024 * 1024, unit: 'MiB' },
    { limit: 1024 * 1024 * 1024, unit: 'GiB' },
    { limit: 1024 * 1024 * 1024 * 1024, unit: 'TiB' },
  ];

  transform(value: number, format?: string): string {
    const formatInfo = format === 'iec' ? this.iec : this.metric;
    let currentFormat = formatInfo[0];
    for (const info of formatInfo) {
      if (info.limit > value) {
        break;
      }
      currentFormat = info;
    }
    return (Math.round((value * 100) / currentFormat.limit) / 100).toString() + ' ' + currentFormat.unit;
  }
}
