@if (label) {
  <div class="label">
    {{ label }}
  </div>
}
@if (icon) {
  <span class="menu-icon" [ngClass]="icon" #menuIcon></span>
}
@if (showMenu) {
  <div #menuContent class="menu-content" [ngClass]="contentClass">
    <div class="menu-canvas" [ngClass]="{ menu: hasMenuItems }">
      <ng-content></ng-content>
    </div>
  </div>
}
