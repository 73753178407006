import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Artifact, ArtifactBase, ArtifactResult, SortCriteria } from '@art-repo/shared/models';
import { Logger } from '@art-repo/shared/utils';
import { ArtiEnvironment, ENVIRONMENT } from '@environments/model';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArtifactService {
  private logger: Logger = new Logger(this.constructor.name);

  constructor(
    private httpClient: HttpClient,
    private messageService: MessageService,
    @Inject(ENVIRONMENT) private environment: ArtiEnvironment,
  ) {}

  public getAllArtifacts(artifactSortCriteria: SortCriteria<ArtifactBase>): Observable<ArtifactResult> {
    return this.httpClient.get<ArtifactResult>(this.environment.apiUrl + 'artifact', {
      params: { sortField: artifactSortCriteria.sortField, orderBy: artifactSortCriteria.orderBy },
    });
  }

  public searchArtifactLike(
    searchString: string,
    artifactSortCriteria: SortCriteria<ArtifactBase>,
  ): Observable<ArtifactResult> {
    return this.httpClient.get<ArtifactResult>(this.environment.apiUrl + 'artifact/search', {
      params: {
        searchString,
        sortField: artifactSortCriteria.sortField,
        orderBy: artifactSortCriteria.orderBy,
      },
    });
  }

  public createArtifact(
    deviceId: string,
    version: string,
    file: { name: string; length: number },
  ): Observable<Artifact> {
    return this.httpClient.post<Artifact>(this.environment.apiUrl + 'artifact', {
      artifactVersion: version,
      deviceId,
      fileName: file.name,
      fileLength: file.length,
    });
  }

  public updateArtifact(artifactId: string, deviceId: string, version: string, fileName: string): Observable<Artifact> {
    return this.httpClient.put<Artifact>(this.environment.apiUrl + 'artifact/' + artifactId, {
      artifactVersion: version,
      deviceId,
      fileName,
    });
  }

  public getArtifact(artifactId: string): Observable<Artifact> {
    return this.httpClient.get<Artifact>(this.environment.apiUrl + `artifact/${artifactId}`);
  }

  public deleteArtifact(artifactId: string): Observable<void> {
    return this.httpClient.delete<void>(this.environment.apiUrl + 'artifact/' + artifactId);
  }

  public downloadArtifact(artifact: Pick<Artifact, 'artifactId' | 'fileName'>): void {
    this.getDownloadUrl(artifact.artifactId).subscribe({
      next: (file) => {
        const a = document.createElement('a');
        a.href = file;
        a.download = artifact.fileName;
        a.click();
        a.remove();
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Download Error',
          detail: `Error during download of file ${artifact.fileName}: ${error.message}`,
        });
        this.logger.error(error);
      },
    });
  }

  private getDownloadUrl(artifactId: string): Observable<string> {
    return this.httpClient.get(this.environment.apiUrl + 'artifact/download/' + artifactId, { responseType: 'text' });
  }
}
