import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ArtiEnvironment, ENVIRONMENT } from '@environments/model';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, map, of } from 'rxjs';
import { Group, GroupIds, GroupNameMap, PageOfGroups } from '../models';

@Injectable({
  providedIn: 'root',
})
export class GroupManagementService {
  constructor(
    private httpClient: HttpClient,
    private oauthService: OAuthService,
    @Inject(ENVIRONMENT) private environment: ArtiEnvironment,
  ) {}

  public getGroups(search: string, max: number, offset: number): Observable<PageOfGroups> {
    let params: HttpParams = new HttpParams().set('max', max).set('offset', offset);

    if (search !== '') {
      params = params.set('search', search);
    }

    return this.httpClient.get<PageOfGroups>(this.environment.groupManagement.apiUrl + 'groups', { params });
  }

  public getUserGroups(): Observable<PageOfGroups> {
    const claims = this.oauthService.getIdentityClaims();
    if (claims === null) {
      return of({ page: [], totalCount: 0, previous: null, next: null, users: [] });
    }
    const userId = claims['sub' as keyof object];
    const params: HttpParams = new HttpParams().set('member', userId);

    return this.httpClient.get<PageOfGroups>(this.environment.groupManagement.apiUrl + 'groups', { params });
  }

  public getGroupsByIds(ids: string[]): Observable<{ groups: Group[] }> {
    const jsonBody: GroupIds = { groups: [] };

    for (const currId of ids) {
      jsonBody.groups.push({ id: currId });
    }

    return this.httpClient.post<{ groups: Group[] }>(
      this.environment.groupManagement.apiUrl + 'groups/byIds',
      JSON.stringify(jsonBody),
    );
  }

  public getGroupNames(ids: Set<string>): Observable<GroupNameMap> {
    const jsonBody: GroupIds = { groups: [] };
    const returnValue: GroupNameMap = {};

    for (const currId of ids) {
      jsonBody.groups.push({ id: currId });
    }

    return this.httpClient
      .post<{ groups: Group[] }>(this.environment.groupManagement.apiUrl + 'groups/byIds', JSON.stringify(jsonBody))
      .pipe(
        map((data) => {
          data.groups.forEach((group) => {
            returnValue[group.id] = group.display_name;
          });
          return returnValue;
        }),
      );
  }
}
