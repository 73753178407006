import { Observable, scan } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private httpClient: HttpClient) {}

  public upload(url: string, formData: FormData): Observable<Upload> {
    const initialState: Upload = { state: 'PENDING', progress: 0 };
    const calculateState = (upload: Upload, event: HttpEvent<unknown>): Upload => {
      if (isHttpProgressEvent(event)) {
        return {
          progress: event.total ? Math.round((100 * event.loaded) / event.total) : upload.progress,
          state: 'IN_PROGRESS',
        };
      }
      if (isHttpResponse(event)) {
        return { progress: 100, state: 'DONE' };
      }
      return upload;
    };
    return this.httpClient
      .post(url, formData, { withCredentials: false, reportProgress: true, observe: 'events' })
      .pipe(scan(calculateState, initialState));
  }
}

export interface Upload {
  progress: number;
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
}

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response;
}

function isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
  return event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress;
}
