import { SlicePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ItemDialogComponent, NoElementsPlaceholderComponent } from '@art-repo/shared/components';
import { AcceptDismissModalComponent } from '@art-repo/shared/components/modals';
import { Artifact, ArtifactBase, OrderBy, SortCriteria } from '@art-repo/shared/models';
import { LocalizedDatePipe } from '@art-repo/shared/pipes';
import { ArtifactService, UserService } from '@art-repo/shared/services';
import { Logger } from '@art-repo/shared/utils';
import { ArtiEnvironment, ENVIRONMENT } from '@environments/model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService, PrimeTemplate } from 'primeng/api';
import { ButtonDirective } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TableModule } from 'primeng/table';

interface EnhancedArtifact extends Artifact {
  isEditable: boolean;
}

@Component({
  selector: 'app-artifact-table',
  templateUrl: './artifact-table.component.html',
  styleUrls: ['./artifact-table.component.scss'],
  standalone: true,
  imports: [
    AcceptDismissModalComponent,
    ScrollPanelModule,
    TableModule,
    PrimeTemplate,
    ButtonDirective,
    NoElementsPlaceholderComponent,
    SlicePipe,
    LocalizedDatePipe,
    TranslateModule,
  ],
})
export class ArtifactTableComponent implements OnInit {
  public apiUrl = this.environment.apiUrl;
  public isAdmin = false;
  public artifacts: EnhancedArtifact[] = [];
  public searching = false;

  private logger: Logger;
  private currentSort: SortCriteria<ArtifactBase> = {
    sortField: 'artifactVersion',
    orderBy: OrderBy.DESC,
  };
  private lastSearchString?: string;

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public artifactService: ArtifactService,
    private dialogService: DialogService,
    private userService: UserService,
    private router: Router,
    @Inject(ENVIRONMENT) private environment: ArtiEnvironment,
  ) {
    this.logger = new Logger(this.constructor.name);
  }

  ngOnInit(): void {
    this.isAdmin = this.userService.isMemberOfGroupId(this.environment.groupManagement.adminGroupId);
  }

  public loadArtifacts(searchString?: string) {
    this.lastSearchString = searchString;

    if (searchString === undefined) {
      return this.loadAllArtifacts();
    }

    this.loadArtifactsByString(searchString);
  }

  public sortArtifacts(sortEvent: { field: keyof ArtifactBase; order: number }) {
    const orderBy = sortEvent.order === 1 ? OrderBy.ASC : OrderBy.DESC;
    // Prevents multiple calls on sort function executed when artifacts input changes etc..
    if (this.currentSort?.sortField === sortEvent.field && this.currentSort.orderBy === orderBy) {
      return;
    }

    this.currentSort = {
      orderBy,
      sortField: sortEvent.field,
    };

    this.loadArtifacts(this.lastSearchString);
  }

  public copyDeepLink(artifactId: string): void {
    navigator.clipboard.writeText(
      `${window.location.protocol}//${window.location.host}/artifacts/${artifactId}/download`,
    );
    this.messageService.add({
      severity: 'info',
      summary: this.translateService.instant('artifactTable.clipboard.artifact-deep-link-summary'),
      detail: this.translateService.instant('artifactTable.clipboard.artifact-deep-link-detail'),
      life: 3000,
    });
  }

  public onDeleteClick(artifactId: string): void {
    this.confirmationService.confirm({
      key: 'delete-artifact-modal',
      accept: () => {
        this.artifactService.deleteArtifact(artifactId).subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('artifactTable.delete.delete-artifact-summary'),
              detail: this.translateService.instant('artifactTable.delete.delete-artifact-detail'),
              life: 3000,
            });
            this.reload();
          },
        });
      },
    });
  }

  private loadAllArtifacts() {
    this.searching = true;
    this.artifactService.getAllArtifacts(this.currentSort).subscribe({
      next: (artifacts) => {
        const temp: EnhancedArtifact[] = [];
        for (const art of artifacts.result) {
          temp.push({
            ...art,
            isEditable: this.userService.isMemberOfGroupId(art.editGroups),
          });
        }
        this.artifacts = temp;
        this.searching = false;
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error during getting all available artifacts from DB',
        });
        this.logger.error(error);
        this.searching = false;
      },
    });
  }

  public allNumbers(productFamily: string, version: string, itemNumbers: string[]) {
    this.dialogService.open(ItemDialogComponent, {
      data: {
        items: itemNumbers,
      },
      header: productFamily + ', Version: ' + version,
      style: {
        'max-width': '80%',
        'min-width': '30%',
        'min-height': '50%',
        'max-height': '80%',
      },
    });
  }

  navigateToDetails(artifactId: string) {
    this.router.navigateByUrl(`/artifacts/${artifactId}`);
  }

  private loadArtifactsByString(searchString: string) {
    this.searching = true;
    this.artifactService.searchArtifactLike(searchString, this.currentSort).subscribe({
      next: (artifacts) => {
        const temp: EnhancedArtifact[] = [];
        for (const art of artifacts.result) {
          temp.push({
            ...art,
            isEditable: this.userService.isMemberOfGroupId(art.editGroups),
          });
        }
        this.artifacts = temp;
        this.searching = false;
      },
      error: (error) => {
        const errorText = `search string ${searchString}`;
        this.messageService.add({
          severity: 'error',
          summary: 'Search Error',
          detail: `Error during search for ${errorText}`,
        });
        this.logger.error(error, errorText);
        this.searching = false;
      },
    });
    return;
  }

  public reload() {
    this.loadArtifacts(this.lastSearchString);
  }
}
