import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export enum LangCodes {
  CZECH = 'cz',
  DANISH = 'da',
  GERMAN = 'de',
  ENGLISH = 'en',
  SPANISH = 'es',
  FINISH = 'fi',
  FRENCH = 'fr',
  ITALIAN = 'it',
  JAPANESE = 'ja',
  KOREAN = 'ko',
  DUTCH = 'nl',
  POLISH = 'pl',
  PORTUGUESE = 'pt',
  RUSSIAN = 'ru',
  SWEDISH = 'sv',
  TURKISH = 'tr',
  CHINESE = 'zh',
  CHINESE_TRADITIONAL = 'zhf',
}

export interface Language {
  code: LangCodes;
  label: string;
  available: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class InternationalizationService {
  public readonly defaultLang: LangCodes = LangCodes.ENGLISH;

  public readonly languages: Language[] = [
    /* { code: LangCodes.CZECH, label: 'Česky', available: true },
    { code: LangCodes.DANISH, label: 'Dansk', available: true }, */
    { code: LangCodes.GERMAN, label: 'Deutsch', available: true },
    { code: LangCodes.ENGLISH, label: 'English', available: true },
    { code: LangCodes.SPANISH, label: 'Español', available: false },
    { code: LangCodes.FRENCH, label: 'Français', available: false },
    { code: LangCodes.ITALIAN, label: 'Italiano', available: false },
    { code: LangCodes.DUTCH, label: 'Nederlands', available: false },
    { code: LangCodes.POLISH, label: 'Polski', available: false },
    { code: LangCodes.PORTUGUESE, label: 'Português', available: false },
    /* { code: LangCodes.RUSSIAN, label: 'Русский', available: true },
    { code: LangCodes.FINISH, label: 'Suomi', available: true },
    { code: LangCodes.SWEDISH, label: 'Svenska', available: true },
    { code: LangCodes.TURKISH, label: 'Türkçe', available: true },
    { code: LangCodes.CHINESE, label: '中文', available: true },
    {
      code: LangCodes.CHINESE_TRADITIONAL,
      label: '中文(台灣)',
      available: true,
    },
    { code: LangCodes.JAPANESE, label: '日本語', available: true },
    { code: LangCodes.KOREAN, label: '한국어', available: true }, */
  ];

  constructor(private translateService: TranslateService) {}

  public setup(): void {
    this.translateService.setDefaultLang(this.defaultLang);
    this.translateService.addLangs(this.getAvailableLanguageCodes());

    registerLocaleData(de);

    const language = this.tryIdentifyPreferredLang();
    this.translateService.use(language || this.defaultLang);
  }

  private getAvailableLanguageCodes(): string[] {
    return this.languages.filter((x) => x.available).map((x) => x.code);
  }

  private tryIdentifyPreferredLang(): string | undefined {
    const currentBrowserLang: string | undefined = this.translateService.getBrowserLang();
    return this.translateService.getLangs().find((lang) => lang === currentBrowserLang);
  }

  public setLanguage(newLang: LangCodes): void {
    if (this.translateService.currentLang === newLang) {
      return;
    }
    this.translateService.use(newLang);
  }
}
