import { Component, EventEmitter, OnInit, Output, Input, OnDestroy, output, model } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonDirective } from 'primeng/button';
import { toObservable } from '@angular/core/rxjs-interop';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  standalone: true,
  imports: [FormsModule, InputTextModule, ButtonDirective, TranslateModule],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Output() public addEntity = new EventEmitter<void>();
  @Output() public reloadEntities = new EventEmitter<void>();
  @Input() public buttonText = 'No Text';
  @Input() public showAddButton = false;

  public readonly searchValue = model('');
  public readonly searchValueChange = output<string | undefined>();

  private readonly debounceInput = toObservable(this.searchValue);
  private readonly onDestroy = new Subject<void>();

  ngOnInit(): void {
    this.debounceInput
      .pipe(takeUntil(this.onDestroy), debounceTime(500), distinctUntilChanged())
      .subscribe((newValue) => this.search(newValue));
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public search(searchString: string): void {
    if (searchString) {
      this.searchValueChange.emit(searchString);
    } else {
      this.searchValueChange.emit(undefined);
    }
  }

  public onAddButtonClick(): void {
    this.addEntity.emit();
  }

  public reload(): void {
    this.reloadEntities.emit();
  }
}
