<div class="search">
  <span class="p-float-label">
    <input
      #searchStringInput
      id="searchStringInput"
      type="text"
      pInputText
      class="searchInput"
      [(ngModel)]="searchValue"
    />
    <label for="searchStringInput">{{ 'tableSearch.searchString' | translate }}</label>
  </span>
  @if (showAddButton) {
    <button
      pButton
      type="button"
      icon="pi pi-plus pi-small"
      label="{{ buttonText }}"
      (click)="onAddButtonClick()"
      class="searchButton"
    ></button>
  }
</div>
