import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SearchBarComponent } from '@art-repo/shared/components';
import { UserService } from '@art-repo/shared/services';
import { ArtiEnvironment, ENVIRONMENT } from '@environments/model';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AddDeviceDialogComponent } from '../add-device-dialog/add-device-dialog.component';
import { DeviceTableComponent } from '../device-table/device-table.component';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-device-overview',
  templateUrl: './device-overview.component.html',
  styleUrls: ['./device-overview.component.scss'],
  standalone: true,
  imports: [SearchBarComponent, DeviceTableComponent, TranslateModule],
})
export class DeviceOverviewComponent implements OnInit {
  @ViewChild('deviceTable')
  private deviceTable?: DeviceTableComponent;

  @ViewChild('searchBar')
  private searchBar?: SearchBarComponent;

  public searchValue = '';
  public isAdmin = false;

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private userService: UserService,
    @Inject(ENVIRONMENT) private environment: ArtiEnvironment,
    private router: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.userService.isMemberOfGroupId(this.environment.groupManagement.adminGroupId);
    this.router.queryParamMap
      .pipe(
        filter((params) => params.has('search')),
        map((searchValue) => searchValue.get('search')!),
      )
      .subscribe({
        next: (searchValue) => (this.searchValue = searchValue),
      });
  }

  public searchDevices(searchString?: string) {
    this.deviceTable?.loadDevices(searchString);
  }

  public addDevice() {
    this.dialogService
      .open(AddDeviceDialogComponent, {
        header: this.translateService.instant('addButton.addDevice'),
        data: {
          isEditMode: false,
        },
        style: {
          'min-width': '28%',
          'max-width': '28%',
          'max-height': '80%',
        },
        focusOnShow: false,
      })
      .onClose.subscribe({
        next: () => this.deviceTable?.reload(),
      });
  }

  public devicesChanged() {
    this.searchBar?.reload();
  }
}
