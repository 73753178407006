<div class="overview">
  <app-search-bar
    #searchBar
    [buttonText]="'addButton.addDevice' | translate"
    (addEntity)="addDevice()"
    [showAddButton]="isAdmin"
    [searchValue]="searchValue"
    (searchValueChange)="searchDevices($event)"
  ></app-search-bar>
  <app-device-table #deviceTable class="table" [isAdmin]="isAdmin"></app-device-table>
</div>
