export enum visibility {
  company = 'company',
  privateOnly = 'private',
  toPublic = 'public',
}

export enum membership {
  member = 'member',
  noMember = 'no-member',
  forbidden = 'forbidden',
}

export interface Group {
  display_name: string;
  description: string;
  visibility: visibility;
  id: string;
  creator_id: string;
  create_timestamp: Date;
  updater_id: string;
  update_timestamp: Date;
  customer_number: string;
  customer_name: string;
}

export interface UserInformation {
  id: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  display_name?: string;
}

export interface PageOfGroups {
  page: Group[];
  totalCount: number;
  previous: string | null;
  next: string | null;
  users: UserInformation[];
}

export interface GroupMembershipResult {
  id: string;
  result: membership;
}

export interface GroupMembershipResults {
  results: GroupMembershipResult[];
}

export interface GroupIds {
  groups: { id: string }[];
}

export type GroupNameMap = Record<string, string>;
