import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchBarComponent } from '@art-repo/shared/components';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AddFirmwareDialogComponent } from '../add-firmware-dialog/add-firmware-dialog.component';
import { ArtifactTableComponent } from '../artifact-table/artifact-table.component';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-artifact-overview',
  templateUrl: './artifact-overview.component.html',
  styleUrls: ['./artifact-overview.component.scss'],
  standalone: true,
  imports: [SearchBarComponent, ArtifactTableComponent, TranslateModule],
})
export class ArtifactOverviewComponent implements OnInit {
  public title = 'artifact-repository-frontend';
  public searchValue = '';

  @ViewChild('artifactTable')
  private artifactTable?: ArtifactTableComponent;

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private router: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.router.queryParamMap
      .pipe(
        filter((params) => params.has('search')),
        map((searchValue) => searchValue.get('search')!),
      )
      .subscribe({
        next: (searchValue) => (this.searchValue = searchValue),
      });
  }

  public searchArtifacts(searchString?: string) {
    this.artifactTable?.loadArtifacts(searchString);
  }

  public addArtifact() {
    this.dialogService
      .open(AddFirmwareDialogComponent, {
        header: this.translateService.instant('addButton.addArtifact'),
        data: {},
        style: {
          'min-width': '28%',
          'max-width': '90%',
          'max-height': '80%',
        },
        focusOnShow: false,
      })
      .onClose.subscribe({
        next: () => this.artifactTable?.reload(),
      });
  }
}
