import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonDirective } from 'primeng/button';
import { Footer } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'app-accept-dismiss-modal',
  templateUrl: './accept-dismiss-modal.component.html',
  styleUrls: ['./accept-dismiss-modal.component.scss'],
  standalone: true,
  imports: [ConfirmDialogModule, Footer, ButtonDirective, TranslateModule],
})
export class AcceptDismissModalComponent implements OnInit {
  @Input() key = '';
  @Input() header = '';
  @Input() message = '';
  @Input() acceptButton = 'buttons.ok';
  @Input() cancelButton = 'buttons.cancel';

  ngOnInit(): void {
    if (!this.header || !this.message) {
      throw new Error('Invalid component configuration');
    }
  }
}
