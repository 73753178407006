<app-accept-dismiss-modal
  key="delete-device-modal"
  header="deleteDeviceDialog.header"
  message="deleteDeviceDialog.message"
  acceptButton="buttons.ok"
  cancelButton="buttons.cancel"
>
</app-accept-dismiss-modal>

<p-dialog
  header="{{ 'deleteErrorDialog.header' | translate }}"
  [(visible)]="showDeleteError"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  [closable]="false"
>
  <div class="deleteError">{{ 'deleteErrorDialog.detail' | translate }}</div>
  <p-footer>
    <button pButton type="button" (click)="hideDeleteError()" [label]="'deleteErrorDialog.button' | translate"></button>
  </p-footer>
</p-dialog>

<p-scrollPanel [style]="{ width: '100%', height: '100%' }">
  <div class="table">
    <p-table [value]="devices" [loading]="searching">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 20%">{{ 'deviceTable.productFamily' | translate }}</th>
          <th style="width: 20%">{{ 'deviceTable.itemNumbers' | translate }}</th>
          <th style="width: 25%">{{ 'deviceTable.editGroups' | translate }}</th>
          <th style="width: 25%">{{ 'deviceTable.downloadGroups' | translate }}</th>
          <th style="width: 10%">{{ 'deviceTable.artifactsCount' | translate }}</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-device>
        <tr>
          <td>{{ device.productFamily }}</td>
          <td>
            @if (device.itemNumbers[0] !== '') {
              <div>
                @for (num of device.itemNumbers | slice: 0 : 3; track num) {
                  <div>
                    <a> {{ '• ' + num }}</a>
                  </div>
                }
                @if (device.itemNumbers.length === 4) {
                  <div>
                    <a>{{ '• ' + device.itemNumbers[3] }}</a>
                  </div>
                }
                @if (device.itemNumbers.length > 4) {
                  <div>
                    <button class="moreBtn" (click)="allItems(device.productFamily, device.itemNumbers)">
                      {{ 'deviceTable.more' | translate }}
                    </button>
                  </div>
                }
              </div>
            }
          </td>
          <td>
            @if (device.editGroups.length > 0) {
              <div>
                @for (num of device.editGroups | slice: 0 : 3; track num) {
                  <div>
                    <a> {{ '• ' + (groupNames[asString(num)] || 'deviceTable.unknownGroup' | translate) }}</a>
                  </div>
                }
                @if (device.editGroups.length === 4) {
                  <div>
                    <a>{{ '• ' + (groupNames[device.editGroups[3]] || 'deviceTable.unknownGroup' | translate) }}</a>
                  </div>
                }
                @if (device.editGroups.length > 4) {
                  <div>
                    <button class="moreBtn" (click)="allItems('deviceTable.editGroups', device.editGroups, true)">
                      {{ 'deviceTable.more' | translate }}
                    </button>
                  </div>
                }
              </div>
            }
          </td>
          <td>
            @if (device.downloadGroups.length > 0) {
              <div>
                @for (num of device.downloadGroups | slice: 0 : 3; track num) {
                  <div>
                    <a> {{ '• ' + (groupNames[asString(num)] || 'deviceTable.unknownGroup' | translate) }}</a>
                  </div>
                }
                @if (device.downloadGroups.length === 4) {
                  <div>
                    <a>{{ '• ' + (groupNames[device.downloadGroups[3]] || 'deviceTable.unknownGroup' | translate) }}</a>
                  </div>
                }
                @if (device.downloadGroups.length > 4) {
                  <div>
                    <button
                      class="moreBtn"
                      (click)="allItems('deviceTable.downloadGroups', device.downloadGroups, true)"
                    >
                      {{ 'deviceTable.more' | translate }}
                    </button>
                  </div>
                }
              </div>
            } @else {
              <a>{{ 'deviceTable.unrestricted' | translate }}</a>
            }
          </td>
          <td>{{ device.artifactIds.length }}</td>
          <td>
            <div class="buttons">
              @if (device.isEditable) {
                <button
                  pButton
                  icon="pi pi-edit"
                  class="ui-button-bare-neutral tableButton"
                  (click)="onEditClick(device)"
                ></button>
              }
              @if (device.isEditable) {
                <button
                  pButton
                  icon="pi pi-delete"
                  class="ui-button-bare-neutral tableButton"
                  (click)="onDeleteClick(device.deviceId, device.artifactIds.length)"
                ></button>
              }
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">
            <app-no-elements-placeholder
              icon="pi-zoom-out"
              text="{{ 'noEntry.deviceText' | translate }}"
            ></app-no-elements-placeholder>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-scrollPanel>
